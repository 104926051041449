import { Injectable } from '@angular/core';
import {AnonymousUserService} from "./anonymous-user.service";
import {PlanHelperService} from "./plan-helper.service";
import {UserHelperService} from "./user-helper.service";
import {DeskManagerHelperService} from "./desk-manager-helper.service";
import {LoginHelperService} from "./login-helper.service";
import {EventsService} from "./events.service";
import {OrganizationStateService} from "./organization-state.service";
import {WebsocketService} from "./websocket.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AnonymousLoginService {

  constructor(private anonymousUserService : AnonymousUserService,
              private planHelperService : PlanHelperService,
              private userHelperService : UserHelperService,
              private deskManagerHelperService: DeskManagerHelperService,
              private loginHelperService: LoginHelperService,
              private eventsService: EventsService,
              private organizationStateService: OrganizationStateService,
              private websocketService: WebsocketService,
              private router : Router,
              ) {

  }


  setReturnPage(url: string) {
    window.sessionStorage.setItem("anonymous_return_page", url);
  }

  getReturnPage() {
    const url = window.sessionStorage.getItem("anonymous_return_page");
    this.clearReturnPageUrl();
    return url;
  }

  clearReturnPageUrl() {
    window.sessionStorage.clear();
  }


  loginWithDeviceId = async (path : string) => {
    const deviceId = await this.anonymousUserService.getDeviceIdOrGenerateNew();
    await this.anonymousUserService.setAnonymousUser();
    await this._login(deviceId, path)
  }



  private _login = async (deviceId : string, path : string) => {
    try {
      this.planHelperService.clear()
      this.userHelperService.clearUser();
      this.deskManagerHelperService.clear();
      await this.loginHelperService.loginWithDeviceId(deviceId);
      this.deskManagerHelperService.initializeWorkspace();
      await this.eventsService.init();
      await this.organizationStateService.init();
      // this.eventsService.log(this.eventsService.events.user.login, {
      //   email: data.username,
      //   provider: data.grant_type
      // });
      this.websocketService.connect();
      const storyId = await this.anonymousUserService.getMainStoryId();
      if(storyId) {
        this.router.navigate(['script-story', storyId, path]);
      }else{
        this.router.navigate(['script-story', path]);
      }

    } catch (error) {
        throw error;
    }
  }



}
